<template>
  <div>
    <div class="filters-row df">
      <div class="float-left">
        <input
          v-model="search"
          style="width: 200px"
          type="text"
          class="form-control d-inline-block mr-2"
          :placeholder="$t('filterTable')"
          @keyup="filterReservations"
        />
        <span
          v-b-tooltip.hover
          class="tip-icon show-on-desktop"
          :title="$t('reservationFilterHint')"
        >
          <font-awesome-icon icon="question-circle" />
        </span>
      </div>
      <DatePickerForReservations
        place-holder="filterColleagues"
        :min-datetime="minDate"
        :reservation-filter-date="colleaguesFilterDate"
        :title="''"
        @setFilterDate="date => setFilterDateForPastReservation(date)"
        @resetFilter="resetPastReservationFilter"
      />
      <b-form-select
        v-model="perPage"
        style="width: 60px"
        class="float-right"
      >
        <option>5</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </b-form-select>
    </div>
    <div class="table-responsive">
      <b-overlay :show="loading" rounded="sm">
        <table
          ref="table"
          class="table-style table-settings table_sort"
        >
          <thead>
            <tr>
              <th>{{ $t('department') }}</th>
              <th>{{ $t('room') }}</th>
              <th>{{ $t('desk') }}</th>
              <th>{{ $t('reservedFor') }}</th>
              <th>
                <span
                  v-b-tooltip.hover
                  class="tip-icon mr-1"
                  :title="$t('forGuests')"
                >
                  <font-awesome-icon icon="user-friends" />
                </span>
              </th>
              <th>
                {{ $t('startsOn') }}
                <span
                  v-b-tooltip.hover
                  class="tip-icon"
                  :title="
                    $t('timezoneHint', {
                      timezone
                    })
                  "
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
                <font-awesome-icon
                  class="sort-icon"
                  :icon="
                    sortBy === 'start_datetime'
                      ? sortAsc
                        ? 'sort-up'
                        : 'sort-down'
                      : 'sort'
                  "
                  @click="changeSort('start_datetime')"
                />
              </th>
              <th>
                {{ $t('endsOn') }}
                <span
                  v-b-tooltip.hover
                  class="tip-icon"
                  :title="
                    $t('timezoneHint', {
                      timezone
                    })
                  "
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
                <font-awesome-icon
                  class="sort-icon"
                  :icon="
                    sortBy === 'end_datetime'
                      ? sortAsc
                        ? 'sort-up'
                        : 'sort-down'
                      : 'sort'
                  "
                  @click="changeSort('end_datetime')"
                />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <template v-for="(reservation, index) in reservations">
              <tr
                :key="index"
                :data-val="
                  reservation.objectName +
                    ' ' +
                    reservation.forUser.username +
                    ' ' +
                    getRoomName(reservation) +
                    ' ' +
                    getReservedForLabel(reservation.forUser)
                "
                class="show-on-desktop"
              >
                <td
                  class="show-label"
                  :data-label="$t('department') + ':'"
                >
                  {{
                    reservation.context.department
                      ? reservation.context.department
                      : ''
                  }}
                </td>
                <td :data-label="$t('room') + ':'">
                  {{ getRoomName(reservation) }}
                </td>
                <td :data-label="$t('desk') + ':'">
                  {{
                    reservation.objectType.toLowerCase() === 'desk'
                      ? reservation.objectName
                      : ''
                  }}
                </td>
                <td :data-label="$t('reservedFor') + ':'">
                  {{ getReservedForLabel(reservation.forUser) }}
                </td>
                <td>
                  <font-awesome-icon
                    v-if="reservationGuests(reservation)"
                    :id="`tooltip-guest-icon${reservation.id}`"
                    icon="user-friends"
                  />
                  <b-tooltip
                    v-if="reservationGuests(reservation)"
                    :target="`tooltip-guest-icon${reservation.id}`"
                    placement="bottom"
                  >
                    {{ $t('for') }}
                    <ul class="tooltip-list-items">
                      <li
                        v-for="(guest,
                        guestIndex) in reservation.guest"
                        :key="guestIndex"
                      >
                        {{ guest }}
                      </li>
                    </ul>
                  </b-tooltip>
                </td>
                <td :data-label="$t('startsOn') + ':'">
                  {{
                    reservation.startDatetime
                      | moment(
                        'timezone',
                        timezone,
                        'ddd DD-MMM, HH:mm'
                      )
                  }}
                </td>
                <td :data-label="$t('endsOn') + ':'">
                  {{
                    reservation.endDatetime
                      | moment('timezone', timezone, 'HH:mm')
                  }}
                </td>
              </tr>
              <tr :key="reservation.id" class="show-on-mobile">
                <td class="show-label" :data-label="$t('who') + ':'">
                  <div
                    v-if="reservationGuests(reservation)"
                    :target="`tooltip-guest-icon${reservation.id}`"
                    placement="bottom"
                  >
                    <ul class="tooltip-list-items">
                      <li
                        v-for="(guest,
                        guestIndex) in reservation.guest"
                        :key="guestIndex"
                      >
                        {{ guest }}
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    {{ getReservedForLabel(reservation.forUser) }}
                    {{
                      reservation.deptName
                        ? ', ' + reservation.deptName
                        : ''
                    }}
                  </div>
                </td>
                <td
                  class="show-label"
                  :data-label="$t('where') + ':'"
                >
                  {{ reservation.objectName }}
                </td>
                <td class="show-label" :data-label="$t('when') + ':'">
                  {{
                    reservation.startDatetime
                      | moment(
                        'timezone',
                        timezone,
                        'ddd DD-MMM, HH:mm'
                      )
                  }}
                  -
                  {{
                    reservation.endDatetime
                      | moment('timezone', timezone, 'HH:mm')
                  }}
                </td>
              </tr>
            </template>
            <div v-if="!reservations.length">
              <tr>
                <td>
                  <strong>{{ $t('noRecordsFound') }}</strong>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </b-overlay>
    </div>
    <div v-if="count" class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="count"
        :per-page="perPage"
        :first-text="$t('first')"
        :last-text="$t('last')"
        align="center"
      />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment-timezone'
import vueMoment from 'vue-moment'
import { mapGetters, mapState } from 'vuex'
import OkkuApi from '@/services/OkkuApi'
import _debounce from 'lodash/debounce'
import DatePickerForReservations from '@/components/common/DatePickerForReservations'

Vue.use(vueMoment, {
  moment
})

export default {
  name: 'AllColleaguesTable',
  components: {
    DatePickerForReservations
  },
  data() {
    return {
      reservations: [],
      currentIndex: null,
      currentPage: 1,
      perPage: 5,
      count: 0,
      search: '',
      sortBy: 'start_datetime',
      sortAsc: true,
      loading: false,
      minDate: moment().toISOString(),
      colleaguesFilterDate: '',
      debounceSearch: null
    }
  },
  computed: {
    ...mapState('common', {
      userInfo: '$userInfo',
      organisation: '$organisation'
    }),
    ...mapState('common', ['desks']),
    ...mapGetters('common', {
      timezone: 'buildingTimezone'
    })
  },
  watch: {
    perPage() {
      this.getColleagues()
    },
    currentPage() {
      this.getColleagues()
    },
    organisation() {
      this.getColleagues()
    }
  },
  mounted() {
    if (this.organisation) {
      this.getColleagues()
    }
  },
  created() {
    this.debounceSearch = _debounce(this.searchHandler, 500)
  },
  methods: {
    searchHandler() {
      this.currentPage = 1
      this.getColleagues()
    },
    async filterReservations(e) {
      if (this.colleaguesFilterDate && this.search) {
        const reservations = [...this.reservations]
        const updatedReservations = []
        reservations.forEach(reservation => {
          if (
            reservation?.objectName
              ?.toLowerCase()
              .includes(this.search?.toLowerCase()) ||
            reservation.forUser?.firstname
              ?.toLowerCase()
              .includes(this.search?.toLowerCase()) ||
            reservation.forUser?.lastname
              ?.toLowerCase()
              .includes(this.search?.toLowerCase())
          ) {
            updatedReservations.push(reservation)
          }
        })
        this.reservations = updatedReservations
        this.count = updatedReservations.length
      } else if (this.colleaguesFilterDate && !this.search) {
        this.setFilterDateForPastReservation(
          this.colleaguesFilterDate
        )
      } else {
        if (this.debounceSearch) {
          // Cancel ongoing debounce
          this.debounceSearch.cancel()
        }
        this.debounceSearch()
      }
    },
    async resetPastReservationFilter() {
      this.colleaguesFilterDate = ''
      this.search = ''
      this.getColleagues()
    },
    async setFilterDateForPastReservation(filterDate) {
      this.colleaguesFilterDate = filterDate
      this.search = ''

      if (filterDate) {
        const { currentPage, perPage, search, sortBy } = this
        const date = moment(filterDate).format('YYYY-MM-DD')
        const orderBy = this.sortAsc ? 'ASC' : 'DESC'
        const from = moment()
          .startOf('day')
          .toISOString()
        const { reservations } = await OkkuApi.getColleagues(
          from,
          currentPage,
          perPage,
          search,
          sortBy,
          orderBy
        )
        const updatedReservations = []
        reservations.forEach(reservation => {
          if (
            moment(reservation.startDatetime).format('YYYY-MM-DD') ===
            date
          ) {
            updatedReservations.push(reservation)
          }
        })
        this.reservations = updatedReservations
        this.count = updatedReservations.length
      }
    },
    changeSort(sortBy) {
      if (sortBy === this.sortBy) {
        this.sortAsc = !this.sortAsc
      } else {
        this.sortBy = sortBy
      }
      this.getColleagues()
    },
    async getColleagues() {
      this.loading = true
      try {
        const { currentPage, perPage, search, sortBy } = this
        const orderBy = this.sortAsc ? 'ASC' : 'DESC'
        const from = moment()
          .startOf('day')
          .toISOString()
        const { reservations, count } = await OkkuApi.getColleagues(
          from,
          currentPage,
          perPage,
          search,
          sortBy,
          orderBy
        )
        this.reservations = reservations
        this.count = count
      } catch (error) {
        this.reservations = []
        this.count = 0
      } finally {
        this.loading = false
      }
    },
    getFloorName(reservation) {
      const asset = this.desks.find(
        element => element.id === this.objectId,
        reservation
      )

      return asset ? asset.floorName : ''
    },
    getRoomName(reservation) {
      if (reservation.objectType.toLowerCase() === 'room') {
        return reservation.objectName
      }
      const room = this.desks.find(
        element => element.id === reservation.objectId
      )
      return room ? room.roomName : ''
    },
    getReservedForLabel(user) {
      let label = `${user.firstname} ${user?.lastname}`
      if (user.isEmergencyResponder) {
        label += ` ${this.$t('emergencyResponder')}`
      }
      return label
    },
    reservationGuests(reservation) {
      if (reservation.guest[0]) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}
table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
  .sort-icon {
    cursor: pointer;
  }
}
ul.tooltip-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@media (max-width: 760px) {
  .show-on-desktop {
    display: none !important;
  }
}
</style>
