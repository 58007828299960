<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-10 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>
            <h1 class="title-1">
              {{ $t('findMyColleague') }}
            </h1>
            <AllColleaguesTable @updateAlert="updateAlert" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AllColleaguesTable from '@/components/common/AllColleaguesTable'

export default {
  name: 'FindMyColleaguesPage',
  components: {
    AllColleaguesTable
  },
  data() {
    return {
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: ''
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    }
  }
}
</script>
